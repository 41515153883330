<template>
  <div class="input_group">
    <div class="subtitle">{{ title }}</div>
    <input
      :type="type"
      v-model="value"
      @input="validateInput"
      @blur="validateInput"
      :placeholder="placeholder"
    />
    <div class="error" v-if="error">{{ errorMessage }}</div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps } from 'vue';

const props = defineProps({
  title: { type: String, default: 'Ваш кошелек' },
  placeholder: { type: String, default: '100-2000' },
  type: {
    type: String,
    default: 'text',
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 1000000000,
  },
});

const value = ref('');
const error = ref(false);
const errorMessage = ref('');

const validateInput = () => {
  if (value.value.length === 0) {
    error.value = true;
    errorMessage.value = 'Введите корректное значение';
  } else if (props.type === 'number') {
    const numValue = Number(value.value);
    if (isNaN(numValue)) {
      error.value = true;
      errorMessage.value = 'Введите числовое значение';
    } else if (numValue < props.min) {
      error.value = true;
      errorMessage.value = `Минимальное значение: ${props.min}`;
    } else if (numValue > props.max) {
      error.value = true;
      errorMessage.value = `Максимальное значение: ${props.max}`;
    } else {
      error.value = false;
      errorMessage.value = '';
    }
  } else {
    // No specific validation for other types
    error.value = false;
    errorMessage.value = '';
  }
};
</script>

<style lang="scss">
.input_group {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .subtitle {
    @include smallText2(var(--gray-50));
  }
  input {
    @include body(var(--secondary-50));
    border-radius: 100px;
    background-color: var(--primary-10);
    padding: 16px;
    border: 1px solid var(--gray-20);
    width: 100%;
    font-family: 'Comfortaa Regular', sans-serif;
    outline: none;
    &::placeholder {
      @include body(var(--secondary-50));
      font-family: 'Comfortaa Regular', sans-serif;
    }
  }
  .error {
    @include smallText2(#ff2b0f);
  }
}
</style>

<template>
  <div class="trade">
    <div class="currency coin">
      <div class="coin__name">
        <Icon name="BNBSvg" />
        <span> COIN </span>
      </div>
      <div class="coin__price">0.71</div>
    </div>
    <div class="currency rubles">
      <div class="coin__name">
        <Icon name="RubSvg" />
        <span> Рубли </span>
      </div>
      <div class="coin__price">500</div>
    </div>
    <div class="exchange">
      <Icon name="ExchangeSvg" />
      <span> Обмен </span>
    </div>
  </div>
  <div class="buttons">
    <ButtonComponent @click="openModal">
      <span>Вывести Рубли</span>
      <Icon name="ArrowRightSvg" />
    </ButtonComponent>
    <TransitionComponent>
      <ModalComponent
        v-if="isModalOpen"
        :isOpen="isModalOpen"
        @close="closeModal"
      >
        <template #warning>
          <TransitionComponent>
            <div class="modal__warning" v-if="warningActive">
              <Icon name="WarningSvg" />
              <div class="warning__texts">
                <span class="title">Ой! Не хватает <Icon name="GemSvg" /></span>
                <span class="subtitle">Можете приобрести в Shop</span>
              </div>
              <router-link to="/shop" class="warning__button">Shop</router-link>
            </div>
          </TransitionComponent>
        </template>
        <template #header>
          <div class="modal__header">
            <div class="header__left">
              <Icon name="RubSvg" />
            </div>
            <div class="header__content">
              <div class="header__texts">
                <span class="title">Вывод средств</span>
                <span class="subtitle">Заполните форму</span>
              </div>
              <button @click="closeModal"><Icon name="CloseSvg" /></button>
            </div>
          </div>
        </template>
        <template #body>
          <div class="modal__body">
            <span>Выберите платежную систему</span>
            <div class="radio-group">
              <RadioGroupComponent
                name="payment-system"
                value="ЮMoney"
                title="ЮMoney"
              />
              <RadioGroupComponent
                name="payment-system"
                value="Банковская карта"
                title="Банковская карта"
              />
              <RadioGroupComponent
                name="payment-system"
                value="USDT TRC20"
                title="USDT TRC20"
              />
            </div>
            <InputGroupComponent
              title="Реквизиты"
              placeholder="Введите реквизиты"
            />
            <InputGroupComponent title="Сумма вывода" placeholder="100-2000₽" />
            <ButtonComponent class="without_submit" @click="showWarning"
              >Вывести</ButtonComponent
            >
          </div>
        </template>
        <template #footer>
          <span class="modal__footer">
            Продолжая, Вы соглашаетесь с офертой
          </span>
        </template>
      </ModalComponent>
    </TransitionComponent>
    <router-link to="/top" class="rating_withouts">
      <div class="gem__block">
        <Icon name="GemWhiteSvg" />
      </div>

      <span>Топ выводов за сегодня</span>
    </router-link>
  </div>
  <ul>
    <li>Выводы</li>
    <li class="active">Обмены</li>
    <li>Лента</li>
  </ul>
  <div class="trades">
    <TradeElementComponent />
    <TradeElementComponent />
    <TradeElementComponent />
    <TradeElementComponent />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import TradeElementComponent from './TradeElementComponent.vue';
import ModalComponent from './ModalComponent.vue';
import TransitionComponent from './TransitionComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import RadioGroupComponent from './RadioGroupComponent.vue';
import InputGroupComponent from './InputGroupComponent.vue';
import Icon from './IconComponent.vue';

const isModalOpen = ref(false);
const withdrawalAmount = ref(0);

const openModal = () => {
  isModalOpen.value = true;
  document.body.style.overflow = 'hidden';
};

const closeModal = () => {
  isModalOpen.value = false;
  withdrawalAmount.value = 0; // Reset input on close
  document.body.style.overflow = 'auto';
};

const warningActive = ref(false);

const showWarning = () => {
  warningActive.value = true;
};
</script>

<style scoped lang="scss">
.radio-group {
  display: flex;

  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
}
.exchange {
  padding: 13px 24px;
  border-radius: 64px;
  background-color: var(--primary-10);
  @include contentCenter;
  position: absolute;
  gap: 8px;
  span {
    @include body(--primary-100);
  }
}
.warning__button {
  padding: 10px 12px;
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid var(--yellow-100);
  @include body(var(--gray-100));
}
.modal__body {
  span {
    @include smallText2(var(--gray-50));
  }
}
.modal__warning {
  background-color: var(--yellow-80);
  padding: 20px;
  border-radius: 12px;
  @include contentCenter;
  justify-content: space-between;
  gap: 14px;
  margin-bottom: 24px;
  width: 100%;
  .warning__texts,
  .title,
  .subtitle {
    @include contentCenter;
    flex-direction: row;
  }
  .title {
    @include body(var(--gray-100));
  }
  .subtitle {
    @include smallText2(var(--gray-100));
  }
  .warning__texts {
    flex-direction: column;
    align-items: baseline;
  }
}
.modal__header {
  @include contentCenter;
  gap: 16px;
  margin-bottom: 24px;
  .header__left {
    padding: 14px;
    background-color: var(--secondary-30);
    border-radius: 7px;
  }
  .header__content {
    width: 100%;
    @include contentCenter;
    justify-content: space-between;
    .header__texts {
      @include contentCenter;
      flex-direction: column;
      span {
        text-align: left;
      }
      .title {
        @include body(var(--gray-100));
      }
      .subtitle {
        @include smallText2(var(--gray-50));
      }
    }
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      align-self: baseline;
    }
  }
}
.modal__footer {
  @include contentCenter;
  @include smallText2(var(--gray-40));
  margin-top: 16px;
}

.trades {
  margin-top: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.trade {
  @include contentCenter;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
}
ul {
  @include contentCenter;
  border-bottom: 1px solid var(--gray-20);
  justify-content: space-between;
  li {
    padding: 16px;

    @include body(var(--gray-50));
    &.active {
      border-bottom: 3px solid var(--primary-100);
      @include body(var(--gray-100));
    }
  }
}
.buttons {
  @include contentCenter;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;

  .rating_withouts {
    display: flex;
    gap: 45px;
    padding: 4px;
    border-radius: 100px;
    width: 100%;
    @include contentCenter;
    justify-content: flex-start;

    border: 1px solid var(--blue-100);
    .gem__block {
      background-color: var(--primary-100);
      border-radius: 100%;
      padding: 9px;
      box-shadow: 0px 0px 8px rgba(38, 77, 215, 0.28);
      @include contentCenter;
    }
    span {
      @include body(var(--gray-100));
      text-decoration: none;
    }
  }
}
.currency {
  .coin__name {
    display: flex;
    gap: 7px;
    @include body(var(--gray-100));
  }
  .coin__price {
    @include body(var(--gray-100));
  }
}
.coin {
  @include tradeCurrency(var(--yellow-100));
}
.rubles {
  @include tradeCurrency(var(--secondary-30));
}
</style>

<template>
  <ul class="menu">
    <li v-for="item in menuItems" :key="item.route" :class="item.class">
      <div>
        <component :is="item.icon" />
      </div>
      <router-link :to="item.route">
        {{ item.text }}
      </router-link>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { defineOptions } from 'vue';
import ShopSvg from './Icons/ShopSvg.vue';
import WalletSvg from './Icons/WalletSvg.vue';
import GamepadSvg from './Icons/GamepadSvg.vue';
import GiftSvg from './Icons/GiftSvg.vue';
import HeartSvg from './Icons/HeartSvg.vue';

defineOptions({
  name: 'MenuComponent',
});

const menuItems = [
  { class: 'shop', icon: ShopSvg, route: '/shop', text: 'Shop' },
  { class: 'wallet', icon: WalletSvg, route: '/wallet', text: 'Кошелек' },
  { class: 'games', icon: GamepadSvg, route: '/games', text: 'Games' },
  { class: 'promocode', icon: GiftSvg, route: '/promocode', text: 'Промокод' },
  {
    class: 'my-dragons',
    icon: HeartSvg,
    route: '/promocode',
    text: 'Мои драконы',
  },
];
</script>

<style scoped lang="scss">
.menu {
    display: grid;
    margin-top: 19px;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 32px;

    li {
        @include contentCenter;
        padding: 4px;
        gap: 16px;

        @each $class, $colors in (
            shop: (var(--yellow-100), var(--yellow-50)),
            wallet: (var(--green-100), var(--green-50)),
            games: (var(--pink-100), var(--pink-50)),
            promocode: (var(--blue-100), var(--blue-50)),
            my-dragons: (var(--blue-100), var(--primary-70))
        ) {
            &.#{$class} {
                @include menuLi(nth($colors, 1), nth($colors, 2));
            }
        }

        div {
            border-radius: 100px;
            @include contentCenter;
            padding: 10px;
        }

        a {
            width: 100%;
            @include body(var(--gray-100));
            text-decoration: none;
        }
    }

    .my-dragons {
        grid-column: span 2;
    }
}
</style>


<template>
    <component :is="icon" />
</template>

<script setup lang="ts">
import { computed, defineProps, defineOptions } from 'vue';
import * as icons from './Icons';

const props = defineProps({
  name: {
    type: String,
    required: true
  }
});

defineOptions({
    name: 'IconComponent',
});
const icon = computed(() => {
  if (props.name in icons) {
    return icons[props.name as keyof typeof icons];
  }
  return undefined;
});
</script>
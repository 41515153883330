
<script setup lang="ts">

import { ref, onMounted, onUnmounted } from 'vue';
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from '@/components/TitleComponent.vue';
import TopWithdrawComponent from '@/components/TopWithdrawComponent.vue';
onMounted(() => {
document.body.classList.add('light');
});


// Удаляем класс при размонтировании

onUnmounted(() => {

document.body.classList.remove('light');

});

const theme = ref<"light" | "dark">("dark"); 
</script>

<template>
    <HeaderComponent :theme="theme" />
    <main>
    <TitleComponent title="Топ выводов за сегодня" :isText="false" :isButton="false" :isInput="false"/>
    <TopWithdrawComponent/>
    </main>
</template>

<style>
</style>
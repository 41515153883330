<template>
  <div class="help">
    <div class="help__header" @click="toggleBody">
      <IconComponent name="ThumbtackSvg" />
      <span> Про вывод средств и как играть можно прочитать тут </span>
      <IconComponent :name="'ArrowUpSvg'" :class="isBodyOpen ? 'active' : ''" />
    </div>
    <transition name="fade">
      <div v-if="isBodyOpen" class="help__body">
        <ul>
          <li>
            <span> 1. Играйте в игры: </span>Играйте в различные игры,
            представленные в данном разделе.
          </li>
          <li>
            <span> 2. Получение SHIB COIN: </span>За любую игру на ваш баланс
            будут начисляться SHIB COIN.
          </li>
          <li>
            <span>3. Конвертация в рубли:</span> Полученные SHIB COIN можно
            мгновенно обменять в рубли.
          </li>
          <li>
            <span> 4. Вывод средств:</span> Обмененные рубли можно вывести на
            любой кошелёк или банковский счет.
          </li>
          <li>
            <span>Примечание: </span>Ваш доход будет зависеть от сложности
            выбранной игры. Мы не устанавливаем дневных лимитов на доход, что
            позволяет вам зарабатывать столько, сколько вы можете.
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import IconComponent from './IconComponent.vue';

const isBodyOpen = ref(false);

const toggleBody = () => {
  isBodyOpen.value = !isBodyOpen.value;
};
</script>

<style scoped lang="scss">
.help {
  padding: 8px 16px;
  border-radius: 16px;
  background-color: var(--secondary-30);
  margin-top: 24px;
  .help__header {
    @include contentCenter;
    gap: 8px;
    cursor: pointer;
  }
  .help__body {
    margin-top: 20px;
    ul {
      @include contentCenter;
      flex-direction: column;
      gap: 24px;
      li{
        @include secondaryText(var(--gray-100));
        span{
            @include body(var(--gray-100));
        }
      }
    }
  }
  span {
    @include secondaryText(var(--gray-100));
  }
  svg {
    transition: 0.5s all;
  }
  svg.active {
    transform: rotateX(180deg);
  }
}

.help__body {
  margin-top: 8px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainView from "@/views/MainView.vue";
import WalletView from "@/views/WalletView.vue";
import PromocodeView from "@/views/PromocodeView.vue";
import GamesView from "@/views/GamesView.vue";
import TopView from "@/views/TopView.vue";
import FaqView from "@/views/FaqView.vue";
import DepositView from '@/views/DepositView.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "main",
    component: MainView,
  },
  {
    path: "/wallet",
    name: "wallet",
    component: WalletView,
  },
  {
    path: "/promocode",
    name: "promocode",
    component: PromocodeView,
  },
  {
    path: "/games",
    name: "games",
    component: GamesView,
  },
  {
    path: "/top",
    name: "top",
    component: TopView,
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqView,
  },
  {
    path: "/deposit",
    name: "deposit",
    component: DepositView,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

<template>
  <svg @click="copyText" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
    <path d="M13.75 18.3333H4.58333C3.3682 18.3319 2.20326 17.8485 1.34403 16.9893C0.484808 16.1301 0.00145554 14.9651 0 13.75L0 4.58333C0.00145554 3.3682 0.484808 2.20326 1.34403 1.34403C2.20326 0.484808 3.3682 0.00145554 4.58333 0L13.75 0C14.9651 0.00145554 16.1301 0.484808 16.9893 1.34403C17.8485 2.20326 18.3319 3.3682 18.3333 4.58333V13.75C18.3319 14.9651 17.8485 16.1301 16.9893 16.9893C16.1301 17.8485 14.9651 18.3319 13.75 18.3333ZM22 17.4167V5.5C22 5.25689 21.9034 5.02373 21.7315 4.85182C21.5596 4.67991 21.3264 4.58333 21.0833 4.58333C20.8402 4.58333 20.6071 4.67991 20.4352 4.85182C20.2632 5.02373 20.1667 5.25689 20.1667 5.5V17.4167C20.1667 18.146 19.8769 18.8455 19.3612 19.3612C18.8455 19.8769 18.146 20.1667 17.4167 20.1667H5.5C5.25689 20.1667 5.02373 20.2632 4.85182 20.4352C4.67991 20.6071 4.58333 20.8402 4.58333 21.0833C4.58333 21.3264 4.67991 21.5596 4.85182 21.7315C5.02373 21.9034 5.25689 22 5.5 22H17.4167C18.6318 21.9985 19.7967 21.5152 20.656 20.656C21.5152 19.7967 21.9985 18.6318 22 17.4167Z" fill="#FF7D04"/>
  </svg>
</template>

<script setup lang="ts">
import { defineProps, defineOptions } from 'vue';

defineOptions({
    name: "CopySvg"
})

const props = defineProps<{
  text: string
}>()

const copyText = () => {
  navigator.clipboard.writeText(props.text)
    .then(() => {
      console.log('Text copied to clipboard');
    })
    .catch(err => {
      console.error('Failed to copy text: ', err);
    });
}
</script>

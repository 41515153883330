<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import TitleComponent from '@/components/TitleComponent.vue';
import DepositComponent from '@/components/DepositComponent.vue';
onMounted(() => {
  document.body.classList.add('light');
});

// Удаляем класс при размонтировании

onUnmounted(() => {
  document.body.classList.remove('light');
});

const theme = ref<'light' | 'dark'>('dark');
</script>

<template>
  <HeaderComponent :theme="theme" />
  <main>
    <TitleComponent
      title="Пополнение"
      :isText="false"
      :isButton="false"
      :isInput="false"
    />
    
    <DepositComponent/>
  </main>
</template>

<style></style>


<script setup lang="ts">

import { ref, onMounted, onUnmounted } from 'vue';
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from '@/components/TitleComponent.vue';
import TradeComponent from '@/components/TradeComponent.vue';

onMounted(() => {
document.body.classList.add('light');
});


// Удаляем класс при размонтировании

onUnmounted(() => {

document.body.classList.remove('light');

});

const theme = ref<"light" | "dark">("dark"); 
</script>

<template>
    <HeaderComponent :theme="theme" />
    <main>
    <TitleComponent title="Ваш кошелек" subtitle='1 COIN = 5P Нажмите кнопку обмен для вывода заработанных COIN на Рубли' :isText="true" :isButton="false"  :isInput="false"/>
    <TradeComponent/>
    </main>
</template>

<style>
</style>
<template>
  <div class="faq_component">
    <div :class="['component__header', {'active': isBodyVisible}]">
      <span class="title">{{ title }}</span>
      <IconComponent name="PlusSvg" @click="toggleBody"/>
    </div>
    <transition name="fade">
      <div v-if="isBodyVisible" class="component__body">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { defineOptions, defineProps, ref } from 'vue';
import IconComponent from '@/components/IconComponent.vue';

defineOptions({
  name: 'FaqComponent',
});

defineProps({
    title: {
        type: String,
        required: true,
    },
});

const isBodyVisible = ref(false);

const toggleBody = () => {
  isBodyVisible.value = !isBodyVisible.value;
};
</script>

<style scoped lang="scss">
.faq_component {
  @include contentCenter;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid var(--gray-20);
  gap: 8px;
  .component__header {
    width: 100%;
    @include contentCenter;
    justify-content: space-between;
    padding-bottom: 16px;
    .title {
      @include body(var(--primary-100));
    }
  }
  .component__body {
    overflow: hidden;
    padding-bottom: 16px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, max-height 0.3s ease;
  max-height: 1000px;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  max-height: 0;
}
</style>

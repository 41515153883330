<template>
  <HeaderComponent :theme="theme" />
  <main>
    <MoneyComponent />
    <DragonComponent />
    <MenuComponent/>
    <CardComponent/>
  </main>
  <FooterComponent/>

  <BackgroundComponent />
</template>

<script setup lang="ts">
import { ref } from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import MoneyComponent from "@/components/MoneyComponent.vue";
import BackgroundComponent from "@/components/Icons/BackgroundComponent.vue";
import DragonComponent from "@/components/DragonComponent.vue";
import MenuComponent from "@/components/MenuComponent.vue";
import CardComponent from "@/components/CardComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

const theme = ref<"light" | "dark">("light"); // Debugging by setting initial value to 'light'
</script>

<style lang="scss">
</style>

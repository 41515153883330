
<script setup lang="ts">

import { ref, onMounted, onUnmounted } from 'vue';
import HeaderComponent from "@/components/HeaderComponent.vue";
import TitleComponent from '@/components/TitleComponent.vue';
import BonusesComponent from '@/components/BonusesComponent.vue';
onMounted(() => {
document.body.classList.add('light');
});


// Удаляем класс при размонтировании

onUnmounted(() => {

document.body.classList.remove('light');

});

const theme = ref<"light" | "dark">("dark"); 
</script>

<template>
    <HeaderComponent :theme="theme" />
    <main>
    <TitleComponent title="Промокод" subtitle='Есть промокод? Введите сюда'  placeholder="Введите промокод" :isText="false" :isButton="true" :isInput="true"/>
    <BonusesComponent/>
    </main>
</template>

<style>
</style>